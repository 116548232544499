import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | Jimmy Ether" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>That Page Is Unfound</h1>
          <p>I hate to tell you this, but that page - if it ever existed - has vaporized. Maybe try the <a href="/">homepage</a>?</p>
          <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
